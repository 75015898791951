import type { ConsentOrPayTest } from './consent-or-pay-test.js';

import { abTestValue as getAbTestValue } from '../../../core/js/ab-test.js';
import {
  isValidConsentOrPaySegment,
  getConsentOrPaySegment,
} from './consent-or-pay-test.js';
import { TCFModal } from '../../js/tcf-modal/TCFModal.js';
import { getBrowserCookieValue } from '../../../../src/aftenposten/helpers/cookie.js';
import { getItemFromLocalStorage } from '../../../core/js/local-storage.js';

const isValidDate = (date: Date): boolean => !isNaN(date.getTime());

export const ACTIVE_CONSENT_OR_PAY_TEST: ConsentOrPayTest = 'adType';

const initTCFModal = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const forcedSegment = urlParams.get('FF_tcf-modal');

  // Remove this check to enable consent or pay test
  if (!forcedSegment) {
    console.debug('TCF Modal: No feature flag set');

    return;
  }

  if (window.isHermesApp) {
    return;
  }

  const consentDate = new Date(getBrowserCookieValue('consentDate') ?? '');

  if (!isValidDate(consentDate) && !forcedSegment) {
    console.debug(`TCF Modal: Consent date is not valid: '${consentDate}'`);

    return;
  }

  const daysSinceConsent = Math.ceil(
    (new Date().getTime() - consentDate.getTime()) / (1000 * 60 * 60 * 24),
  );

  if (daysSinceConsent < 14 && !forcedSegment) {
    console.debug(
      `TCF Modal: Not enough days since consent: ${daysSinceConsent}/14`,
    );

    return;
  }

  if (getItemFromLocalStorage('tcf-modal-dismissed') && !forcedSegment) {
    console.debug(
      'TCF Modal: User was exposed to and dismissed the previous test',
    );

    return;
  }

  const abTestValue = getAbTestValue() || NaN;

  // If the forced segment is not actually a segment, then base segment on AB test value.
  // This enables us to force modal rendering and still control the segment using AB test value.
  const segment = isValidConsentOrPaySegment(forcedSegment)
    ? forcedSegment
    : getConsentOrPaySegment(ACTIVE_CONSENT_OR_PAY_TEST, abTestValue);

  if (!segment) {
    console.debug(
      `TCF Modal: AB test value ${abTestValue} is not included in the test`,
    );

    return;
  }

  const config = {
    srcBaseUrl: window.location.origin,
    localStorageDismissedKey: 'tcf-modal-dismissed',
    enabled: true,
  };

  try {
    TCFModal.setup(config);
    TCFModal.open('tcf-modal/consentpay', { segment });
    console.log(`TCFModal initialized successfully with segment: ${segment}`);
  } catch (err) {
    console.warn('Error initializing TCFModal: ', err);
  }
};

export { initTCFModal };
