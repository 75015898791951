export function getCookieValue(name: string): string {
  const cookiesExploded = document.cookie.split(';');

  for (let i = 0; i < cookiesExploded.length; i++) {
    const cookieParts = cookiesExploded[i].split('=', 2);
    const cookieName = cookieParts[0].replace(/^\s+|\s+$/g, ''); // trim whitespaces
    const cookieValue = cookieParts[1];

    if (cookieName === name) {
      return decodeURIComponent(cookieValue);
    }
  }

  return '';
}
