import {
  saveItemToLocalStorage,
  getItemFromLocalStorage,
} from '../../../core/js/local-storage.js';

export class TCFModal {
  private static srcBaseUrl: URL;
  private static initialized: boolean = false;
  private static enabled: boolean = true;
  private static listening: boolean = false;
  private static localStorageKey: string;
  private static currentIframe?: HTMLIFrameElement;

  public static setup(config: TCFModalConfig): void {
    TCFModal.localStorageKey = config.localStorageDismissedKey;
    TCFModal.enabled =
      (config.enabled ?? true) &&
      !getItemFromLocalStorage(TCFModal.localStorageKey);

    try {
      TCFModal.srcBaseUrl = new URL(config.srcBaseUrl);
    } catch {
      throw new this.TCFModalError(
        `Provided \`baseUrl\` is invalid! (${config.srcBaseUrl})`,
      );
    }

    TCFModal.initialized = true;
  }

  /**
   * Open a new modal with a specified path for content.
   * @param path path segment for the new iframe's content URL.
   * @param data optional data to be passed to the modal.
   * @returns `true` if the modal opened. Otherwise `false`.
   */
  public static open(path: string, data?: Record<string, unknown>): boolean {
    if (!TCFModal.initialized) {
      throw new this.TCFModalError(
        'Setup must be performed before opening the modal! Usage: `TCFModal.setup({...options}); TCFModal.open()`',
      );
    }

    if (!TCFModal.enabled) return false;

    const url = new URL(path, TCFModal.srcBaseUrl);

    if (data) {
      Object.keys(data).forEach((key) => {
        url.searchParams.set(key, String(data[key]));
      });
    }

    const iframe = document.createElement('iframe');
    iframe.src = url.href;
    iframe.classList.add('tcf-modal-frame');
    TCFModal.listen();
    TCFModal.currentIframe = iframe;
    document.body.appendChild(iframe);
    document.body.style.overflow = 'hidden';

    return true;
  }

  public static close(): boolean {
    if (!TCFModal.currentIframe || !TCFModal.isOpen()) return false;

    TCFModal.mute();
    document.body.removeChild(TCFModal.currentIframe);
    TCFModal.currentIframe = undefined;
    document.body.style.overflow = '';

    return true;
  }

  private static eventHandler(e: MessageEvent<TCFModalEvent>): void {
    if (
      !(
        e.origin === TCFModal.srcBaseUrl.origin &&
        e.source === TCFModal.currentIframe?.contentWindow
      ) ||
      e.data?.kind !== 'event'
    )
      return;

    const modalEvent = e.data;

    if (modalEvent.name === 'modal_close') {
      TCFModal.close();
      saveItemToLocalStorage(TCFModal.localStorageKey, Date.now().toString());

      return;
    }

    if (modalEvent.name === 'modal_replace') {
      TCFModal.close();
      TCFModal.open(modalEvent.data.path);

      return;
    }

    if (modalEvent.name === 'mark_as_seen') {
      saveItemToLocalStorage(TCFModal.localStorageKey, Date.now().toString());

      return;
    }

    if (modalEvent.name === 'track_click') {
      window.pulse('track', 'engagementEvent', {
        action: 'Click',
        object: { ...modalEvent.data },
      });

      return;
    }

    if (modalEvent.name === 'track_view') {
      window.pulse('track', 'engagementEvent', {
        type: 'View',
        action: 'Click',
        object: { ...modalEvent.data },
      });

      return;
    }
  }

  /**
   * Start listening to message events.
   */
  private static listen(): void {
    if (TCFModal.listening) return;
    TCFModal.listening = true;
    window.addEventListener('message', TCFModal.eventHandler);
  }

  /**
   * Stop listening to message events.
   */
  private static mute(): void {
    if (!TCFModal.listening) return;
    window.removeEventListener('message', TCFModal.eventHandler);
    TCFModal.listening = false;
  }

  public static isOpen(): boolean {
    return (
      !!TCFModal.currentIframe && document.body.contains(TCFModal.currentIframe)
    );
  }

  private static TCFModalError = class extends Error {
    constructor(msg: string) {
      super(`[TCFModal] :: ${msg ?? 'Unknown error.'}`);
    }
  };
}

interface TCFModalConfig {
  srcBaseUrl: string;
  localStorageDismissedKey: string;
  enabled?: boolean;
}

interface TCFModalEventBase {
  context: string;
  kind: string;
  name: string;
}

interface TCFModalCloseEvent extends TCFModalEventBase {
  name: 'modal_close';
}

interface TCFModalReplaceEvent extends TCFModalEventBase {
  name: 'modal_replace';
  data: {
    path: string;
  };
}

interface TCFModalMarkAsSeenEvent extends TCFModalEventBase {
  name: 'mark_as_seen';
}

interface TCFModalTrackClickEvent extends TCFModalEventBase {
  name: 'track_click';
  data: {
    id: string;
    type: string;
    name: string;
    custom: Record<string, unknown>;
  };
}

interface TCFModalTrackViewEvent extends TCFModalEventBase {
  name: 'track_view';
  data: {
    id: string;
    type: string;
    name: string;
  };
}

type TCFModalEvent =
  | TCFModalCloseEvent
  | TCFModalReplaceEvent
  | TCFModalMarkAsSeenEvent
  | TCFModalTrackClickEvent
  | TCFModalTrackViewEvent;
