const isLocalStorageSupported =
  typeof window !== 'undefined' &&
  typeof Storage !== 'undefined' &&
  !!localStorage &&
  typeof localStorage.getItem === 'function' &&
  typeof localStorage.setItem === 'function';

const getItemFromLocalStorage = (key: string): string | null => {
  if (isLocalStorageSupported) {
    return window.localStorage.getItem(key);
  }

  return null;
};

const saveItemToLocalStorage = (key: string, value: string): void => {
  if (isLocalStorageSupported) {
    window.localStorage.setItem(key, value);
  }
};

const removeItemFromLocalStorage = (key: string): void => {
  if (isLocalStorageSupported) {
    window.localStorage.removeItem(key);
  }
};

export {
  isLocalStorageSupported,
  getItemFromLocalStorage,
  saveItemToLocalStorage,
  removeItemFromLocalStorage,
};
