import { console } from '../console/index.js';

if (!window.frontendConfig) {
  console.error(
    'It looks that you are trying to read frontend config, but you have not included it in your HTML template',
  );
}

export const frontendConfig = window.frontendConfig;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const frontendConfigWithOverwrite = (variant: string) =>
  window.frontendConfig;
