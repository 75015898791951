import {
  isAndroidApp,
  isMobileApp,
} from '../../../src/core/utils/is-mobile.js';

function getMetaDataAttribute(meta: string, dataAttribute: string) {
  const metaName = document.querySelector(
    `meta[name='${meta}']`,
  ) as HTMLMetaElement;

  return metaName?.dataset[dataAttribute] ?? null;
}

function getMetaContentAttribute(meta: string) {
  const metaName = document.querySelector(
    `meta[name='${meta}']`,
  ) as HTMLMetaElement;

  return metaName?.content ?? null;
}

export const fasten = {
  publicationId: function (): string {
    return getMetaContentAttribute('publication-id');
  },
  siteVersion: function (): string {
    return getMetaContentAttribute('site-version');
  },
  sectionName: function (): string | null {
    return getMetaDataAttribute('section-info', 'sectionName');
  },
  sectionPath: function (): string | null {
    return getMetaDataAttribute('section-info', 'sectionPath');
  },
  pathName: function (): string {
    return window.location.pathname.replace(/^\//, '');
  },
  isMobileApp,
  isAndroidApp,
  fetch: function <T>(
    method: string,
    url: string,
    headers: Record<string, string> | null = null,
    responseType: XMLHttpRequest['responseType'] = 'json',
    withCredentials = false,
    body: FormData | string | null = null,
  ): Promise<T> {
    return new Promise(function (resolve, reject) {
      const request = new XMLHttpRequest();

      request.open(method, url);

      if (headers) {
        Object.keys(headers).forEach((headerName) =>
          request.setRequestHeader(headerName, headers[headerName]),
        );
      }

      request.responseType = responseType;
      request.withCredentials = withCredentials;

      request.onload = function () {
        if (this.status >= 200 && this.status < 300) {
          resolve(request.response);
        } else {
          reject({
            status: this.status,
            statusText: request.statusText,
          });
        }
      };

      request.onerror = function () {
        reject({
          status: this.status,
          statusText: request.statusText,
        });
      };

      request.send(body);
    });
  },
  devMode: getMetaDataAttribute('section-info', 'devMode') === 'true',
};
