import type {
  SpidSession,
  PulseSession,
  AnonymousSession,
} from './user-types.js';
import type { DefinedUserAbGroup } from '../../../config/types/index.js';

export const SPID_SESSION_TYPE = 'spid';
export const PULSE_SESSION_TYPE = 'pulse';
export const ANONYMOUS_SESSION_TYPE = 'anonymous';

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export const isSpidSession = (spidSession: any): spidSession is SpidSession =>
  !!spidSession &&
  typeof spidSession === 'object' &&
  spidSession.type === SPID_SESSION_TYPE &&
  !!spidSession.userId;

export const isPulseSession = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  pulseSession: any,
): pulseSession is PulseSession =>
  !!pulseSession &&
  typeof pulseSession === 'object' &&
  pulseSession.type === PULSE_SESSION_TYPE &&
  !!pulseSession.userId;

export const isAnonymousSession = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  anonymousSession: any,
): anonymousSession is AnonymousSession =>
  !!anonymousSession &&
  typeof anonymousSession === 'object' &&
  anonymousSession.type === ANONYMOUS_SESSION_TYPE;

export const isUserAbGroup = (
  abValue: unknown,
): abValue is DefinedUserAbGroup =>
  typeof abValue === 'number' &&
  Number.isInteger(abValue) &&
  abValue >= 0 &&
  abValue <= 9;
