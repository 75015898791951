import type { CacheableSessionData } from './types.js';

export const setBodyClasses = (sessionData: CacheableSessionData): void => {
  const bodyClasses = document.body.classList;

  if (sessionData) {
    bodyClasses.remove('imp-no-session');
    bodyClasses.add('imp-logged-in');

    if (sessionData.access.entitled) {
      bodyClasses.add('imp-has-product');
    } else {
      bodyClasses.remove('imp-has-product');
    }
  } else {
    bodyClasses.remove('imp-logged-in');
    bodyClasses.remove('imp-has-product');
    bodyClasses.add('imp-no-session');
  }
};

const expireDays = (days: number): string => {
  const expireDate = new Date();

  expireDate.setDate(expireDate.getDate() + days);

  return expireDate.toUTCString();
};

export const setSessionCookies = (sessionData: CacheableSessionData): void => {
  if (sessionData) {
    document.cookie = `curate-Id=${
      sessionData.user.userId
    }; expires=${expireDays(14)}; path=/;`;
    document.cookie = `SP_ABO=${sessionData.access.allowedFeatures.join(
      ',',
    )}; expires=${expireDays(365)}; path=/;`;
  } else {
    document.cookie =
      'curate-Id=nothing; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;';
    document.cookie =
      'SP_ABO=nothing; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;';
  }
};
