import type { Identity } from '@schibsted/account-sdk-browser';

import {
  getItemFromLocalStorage,
  saveItemToLocalStorage,
} from '../local-storage-with-ttl.js';
import { frontendConfig } from '../../../../src/core/system/config/frontend.server.js';

// store to same key as for Hyperion
const SIMPLIFIED_LOGIN_DISABLED = 'simplified_login_state';
const TTL = 2 * 60 * 60 * 1000; // 2 hours

const disableSimplifiedLoginWidgetForSomeTime = (): void => {
  saveItemToLocalStorage(SIMPLIFIED_LOGIN_DISABLED, true, TTL);
};

const shouldShowSimplifiedLogin = (): boolean => {
  return (
    frontendConfig.accountSdkConfig.simplifiedLoginWidget &&
    !getItemFromLocalStorage<boolean>(SIMPLIFIED_LOGIN_DISABLED)
  );
};

export const showSimplifiedLoginWidget = (identity: Identity) => {
  if (shouldShowSimplifiedLogin()) {
    identity
      .showSimplifiedLoginWidget({ state: 'fallback' })
      .then((data) => {
        if (data) disableSimplifiedLoginWidgetForSomeTime();
      })
      .catch((error) => {
        if (!error.toString().includes('Missing user data')) {
          console.error('Simplified login widget error', error);
        }
      });
  }
};
