import type {
  DefinedUserAbGroup,
  UserAbGroup,
} from '../../../config/types/user.js';

import { getCookieValue } from './cookies.js';
import { getSessionData } from './schibsted-account/index.server.js';
import { basicHash } from '../../../src/core/utils/basic-hash.js';
import { getCachedSessionData } from './schibsted-account/session-cache.js';
import { isUserAbGroup } from '../../../src/core/user/user-types-guards.js';

/**
 * Reads the ab-test value from the cookie named `abx2`.
 *
 * @returns The ab-test value if it is a number in the range 0-99, otherwise null.
 */
export function abTestValue(): number | null {
  const abTestValue = getCookieValue('abx2');
  const number = parseInt(abTestValue, 10);

  if (number < 0 || number >= 100 || Number.isNaN(number)) {
    return null;
  }

  return number;
}

// Keep in sync with: src/core/utils/get-user-ab-group.ts
const getUserAbGroupOverride = (): UserAbGroup => {
  const searchParams = new URLSearchParams(window.location.search);
  const override = searchParams.get('userabgroup');

  if (override === null) {
    return undefined;
  }

  const number = Number.parseInt(override, 10);

  if (isUserAbGroup(number)) {
    return number as DefinedUserAbGroup;
  }

  return undefined;
};

const calculateUserAbGroup = (input: string): UserAbGroup => {
  if (!input || input.length === 0) {
    return undefined;
  }

  return Math.abs(basicHash(input) % 10) as DefinedUserAbGroup;
};

export async function userAbGroup(): Promise<UserAbGroup> {
  const override = getUserAbGroupOverride();

  if (override !== undefined) {
    return override;
  }

  const cachedSessionData = getCachedSessionData();

  if (cachedSessionData) {
    return calculateUserAbGroup(cachedSessionData.user.userId.toString());
  }

  try {
    const sessionData = await getSessionData();

    if (!sessionData) {
      return undefined;
    }

    return calculateUserAbGroup(sessionData.user.userId.toString());
  } catch (error) {
    console.error(
      'Failed to calculate A/B group - failed to get user session data',
      error,
    );

    return undefined;
  }
}
