const CONSENT_OR_PAY_SEGMENTS = ['a-free', 'a-targeting'] as const;

export type ConsentOrPaySegment = (typeof CONSENT_OR_PAY_SEGMENTS)[number];

export type ConsentOrPayTest = 'design' | 'adType' | 'price';

type SegmentsConfig = {
  abxRange: {
    start: number;
    end: number;
  };
  segment: ConsentOrPaySegment;
}[];

const testToAbxRange: Record<ConsentOrPayTest, { start: number; end: number }> =
  {
    design: { start: 50, end: 64 }, // 15% (divisible by 3 segments)
    adType: { start: 65, end: 80 }, // 16% (divisible by 2 segments)
    price: { start: 81, end: 90 }, // 15% (divisible by 3 segments)
  };

// const segmentsConfig: SegmentsConfig = [
//   { abxRange: { start: 50, end: 54 }, segment: 'a-free' }, // 5%
//   { abxRange: { start: 55, end: 59 }, segment: 'b-free' }, // 5%
//   { abxRange: { start: 60, end: 64 }, segment: 'c-free' }, // 5%
// ];

/* adType */
const segmentsConfig: SegmentsConfig = [
  { abxRange: { start: 66, end: 73 }, segment: 'a-free' },
  { abxRange: { start: 74, end: 81 }, segment: 'a-targeting' },
];

// /* price */
// const segmentsConfig: SegmentsConfig = [
//   { abxRange: { start: 81, end: 85 }, segment: 'a-free-49' },
//   { abxRange: { start: 86, end: 90 }, segment: 'a-free-99' },
// ];

const isInTestGroup = (
  activeTest: ConsentOrPayTest,
  abTestValue: number,
): boolean =>
  abTestValue >= testToAbxRange[activeTest].start &&
  abTestValue <= testToAbxRange[activeTest].end;

const isValidConsentOrPaySegment = (
  segment: string | null,
): segment is ConsentOrPaySegment =>
  !!segment && CONSENT_OR_PAY_SEGMENTS.includes(segment as ConsentOrPaySegment);

const getConsentOrPaySegment = (
  activeTest: ConsentOrPayTest,
  abTestValue: number,
): ConsentOrPaySegment | undefined => {
  if (!isInTestGroup(activeTest, abTestValue)) return undefined;

  return segmentsConfig.find(
    (config) =>
      abTestValue >= config.abxRange.start &&
      abTestValue <= config.abxRange.end,
  )?.segment;
};

export { getConsentOrPaySegment, isValidConsentOrPaySegment };
