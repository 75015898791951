import { Identity, Monetization } from '@schibsted/account-sdk-browser';

import type { SimplifiedLoginData } from '@schibsted/account-sdk-browser';
import type { LoginActionConfig, SessionData, UserStatus } from './types.js';

import { frontendConfig } from '../../../../src/core/system/config/frontend.server.js';
import { setBodyClasses, setSessionCookies } from './side-effects.js';
import {
  clearCachedSessionData,
  getCachedSessionData,
  setCachedSessionData,
} from './session-cache.js';
import { showSimplifiedLoginWidget } from './simplified-login.js';
import { fasten } from '../frontend-api.js';

type PreparedAccountSdkConfig = {
  clientId: string;
  sessionDomain: string;
  redirectUri: string;
  env: string;
  varnishCookie: {
    expiresIn: number;
    domain: string;
  };
};

const identityConfig = ((): PreparedAccountSdkConfig => {
  const {
    clientId,
    env,
    sessionDomain,
    redirectUriDomain,
    useOriginAsRedirectUri,
    redirectUriPath,
    varnishCookieExpiresIn,
  } = frontendConfig.accountSdkConfig;

  const redirectUri = `${
    env === 'PRE' || useOriginAsRedirectUri
      ? window.location.origin
      : redirectUriDomain
  }${redirectUriPath}`;

  return {
    clientId,
    redirectUri,
    sessionDomain,
    env,
    varnishCookie: {
      expiresIn: varnishCookieExpiresIn,
      domain: sessionDomain.split('.').slice(-2).join('.'),
    },
  };
})();

const identity = new Identity(identityConfig);
const monetization = new Monetization(identityConfig);

let obtainingSessionPromise: Promise<SessionData> = null;

window.Identity = identity;

/**
 * This is used to obtain user session and apply some side effects:
 * 1. set body classes to indicate user/product status
 * 2. set/refresh cookies related to user session
 * 3. init the simplified login widget if needed
 */
export const initUserSession = async (): Promise<void> => {
  identity.enableVarnishCookie(identityConfig.varnishCookie);

  const cachedSessionData = getCachedSessionData();

  setBodyClasses(cachedSessionData);

  const sessionData = await getSessionData();

  setBodyClasses(sessionData);
  setSessionCookies(sessionData);

  if (
    frontendConfig.accountSdkConfig.simplifiedLoginWidget &&
    !sessionData &&
    !fasten.isMobileApp()
  ) {
    showSimplifiedLoginWidget(identity);
  }
};

export const getSessionData = async (): Promise<SessionData> => {
  if (obtainingSessionPromise) {
    return obtainingSessionPromise;
  }

  obtainingSessionPromise = (async () => {
    const loggedIn = await identity.isLoggedIn();

    if (loggedIn) {
      const { userId, uuid, familyName, givenName, sig, result, userStatus } =
        await identity.getUser();
      const { entitled = false, allowedFeatures = [] } =
        (await monetization.hasAccess(
          window.frontendConfig.accountSdkConfig.productId.split(','),
          userId,
        )) || {};

      return {
        user: {
          familyName,
          givenName,
          userId,
          uuid,
          result,
          userStatus,
          userToken: sig,
        },
        access: {
          entitled,
          allowedFeatures,
        },
      };
    } else {
      return false;
    }
  })();

  obtainingSessionPromise
    .then((sessionData) => {
      if (sessionData) {
        setCachedSessionData(sessionData);
      } else {
        clearCachedSessionData();
      }
    })
    .catch(() => {
      clearCachedSessionData();
    });

  return obtainingSessionPromise;
};

export const getLoginUrl = (): string => {
  const state = 'dunno';

  return identity.loginUrl({ state });
};

export const loginAction = (
  config: LoginActionConfig = { state: 'fallbackState' },
): void => {
  identity.login(config);
};

export const logoutAction = (): void => {
  clearCachedSessionData();
  setSessionCookies(null);

  identity.logout(window.location.href);
};

export const getUserStatus = async (): Promise<UserStatus> => {
  const sessionData = await getSessionData();

  const isLoggedIn = sessionData && !!sessionData.user.userId;
  const isSubscriber = sessionData && sessionData.access.entitled;

  return {
    isLoggedIn,
    isSubscriber,
  };
};

export const getUserContextData = async (): Promise<SimplifiedLoginData> => {
  return await identity.getUserContextData();
};

window.accountSession = {
  loginAction,
};
